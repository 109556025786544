<template>
  <div class="iss-main mt-16">
    <div class="iss-search-wrap">
      <!--      <search-form :items="items" @fnSearch="handleFnSearch" />-->
      <a-input-search
        v-model:value="inputVal"
        placeholder="请输入活动名称搜索"
        style="width: 200px"
        @search="handleFnSearch(inputVal)"
      />
    </div>
    <grid
      ref="gridRef"
      :columns="columns"
      :code="$route.path"
      :url="url"
      :search="search"
      :btn-operation="btnOperation"
      :scroll="{ x: 1200, y: gridHeight }"
    >
      <template #operation="{ record }">
        <operation :options="options" :record="record" />
      </template>

      <template #activityUrl="{ record }">
        <tempalte v-if="record.pubStatus === 1">
          <a @click="handleClickByCopyLink(record.id)">复制链接</a>
          <a-divider type="vertical" />
          <a :href="getVisitLink(record.id)" target="_blank">直接打开</a>
        </tempalte>
        <a v-else disabled>暂未发布</a>
      </template>

      <template #questionCode="{ record }">
        <template v-if="record.pubStatus === 1">
          <vue-qr :text="getVisitLink(record.id)" :margin="0" :size="70" />
        </template>
        <a v-else disabled>暂未发布</a>
      </template>

      <template #pubStatus="{ text }">
        <span class="score-level" v-if="text === 0">未发布</span>
        <span class="score-level" v-if="text === 1">已发布</span>
      </template>
    </grid>
    <iss-form-edit
      v-model:visible="visible"
      :init-value="activeItem"
      @fnOk="handleFnOkByEdit"
    />
  </div>
</template>

<script>
// toRefs, watch reactive,
import { reactive, ref, toRefs } from 'vue';
import Grid from '@/components/grid';
import { useStore } from 'vuex';
import VueQr from 'vue-qr/src';
import Operation from '@/components/operation';
import { Divider, message } from 'ant-design-vue';
import IssFormEdit from './components/edit';
import { copyText } from '@/utils';
import activityCenterApi from '@/api/activityCenter';
import { useRouter } from 'vue-router';
import { session } from '@/utils/storage';

export default {
  name: 'index',
  components: {
    Grid,
    Operation,
    IssFormEdit,
    VueQr,
    ADivider: Divider,
  },
  setup() {
    const store = useStore();
    const gridRef = ref();
    const router = useRouter();
    const state = reactive({
      inputVal: '',
      search: {},
      visible: false,
      activeItem: {},
      formList: [],
    });

    const getVisitLink = activityId =>
      `${process.env.VUE_APP_FORM_CLIENT_URL}/issmart-activity-center/events/${store.state.account.tenant}/${activityId}`;

    // const getFormList = id => {
    //   activityCenterApi
    //     .formList('', {
    //       campaignId: id,
    //     })
    //     .then(res => {
    //       state.formList = res;
    //       const NewFormMeeting = state.formList.find((i)=> item.formMeeting === i.formMeeting)
    //       if (!NewFormMeeting) {
    //         item.formMeeting = ''
    //       }
    //     });
    // };

    return {
      ...toRefs(state),
      gridRef,
      router,
      url: activityCenterApi.pageUrl,
      gridHeight: document.body.clientHeight - 280,
      handleFnSearch: value => {
        let obj = { title: value };
        const temp = Object.assign({}, obj);
        state.search = temp;
      },
      getVisitLink,
      // 复制链接
      handleClickByCopyLink: id => {
        copyText(getVisitLink(id));
        message.success('复制链接成功！');
      },
      columns: [
        {
          dataIndex: 'title',
          title: '活动标题',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'pubStatus',
          title: '状态',
          width: 120,
          slots: { customRender: 'pubStatus' },
        },
        // {
        //   dataIndex: 'distributeCount',
        //   title: '报名人数',
        //   width: 100,
        //   ellipsis: true,
        // },
        {
          key: 'questionCode',
          title: '二维码',
          width: 180,
          slots: { customRender: 'questionCode' },
        },

        {
          dataIndex: 'activityUrl',
          title: '活动URL',
          width: 180,
          slots: { customRender: 'activityUrl' },
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 160,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'bind:add',
          fnClick: () => {
            state.visible = true;
            state.activeItem = {};
          },
        },
      ],
      options: [
        {
          type: 'update',
          label: '内容设计',
          permission: 'form:update',
          fnClick: record => {
            // 去到创建活动页面
            store.commit('common/setActivityId', record.id);
            activityCenterApi
              .getActivityCenter('', { activityId: record.id })
              .then(res => {
                session.set('update', res);
                if (res.json) {
                  store.commit(
                    'common/setBannerChart',
                    JSON.parse(res.json).banner
                  );
                  // todo 在这里处理 关联会议和表单删除、过期、未发布的异常场景
                  let content = JSON.parse(res.json).content;
                  content.map(item => {
                    if (item.componentType === 'FORM') {
                      // 关联会议接口
                      activityCenterApi.queryCampaignList('', {}).then(res => {
                        // 过滤拿到 会议id
                        const NewMeetingList = res.find(
                          i => item.campaignName === i.id
                        );
                        if (NewMeetingList) {
                          activityCenterApi
                            .formList('', {
                              campaignId: NewMeetingList.campaignId,
                            })
                            .then(res => {
                              const NewFormMeeting = res.find(
                                i => item.formMeeting === i.id
                              );
                              if (!NewFormMeeting) {
                                item.formUrl = '';
                                item.formMeeting = '';
                                item.formUrl = '';
                              }
                            });
                        } else {
                          item.formUrl = '';
                          item.campaignName = '';
                          item.formMeeting = '';
                        }
                      });
                    }
                  });

                  console.log('content', content);
                  store.commit('common/setQuestionnaireFields', content);

                  store.commit(
                    'common/setQuestionnaire',
                    JSON.parse(res.json).title
                  );
                  // 已经有数据现在编辑，那么需要存起id
                  store.commit('common/setId', res.id);

                  router.push({
                    name: 'designer',
                  });

                  let { describeDetail, title } = res;
                  let titleObj = {
                    activityTitle: title,
                    description: describeDetail,
                  };
                  store.commit('common/setQuestionnaire', titleObj);
                } else {
                  //  新增没有数据，清空id
                  store.commit('common/setId', null);
                  store.commit('common/setBannerChart', {});
                  store.commit('common/setQuestionnaireFields', []);

                  router.push({
                    name: 'designer',
                  });

                  let { describeDetail, title } = res;
                  let titleObj = {
                    activityTitle: title,
                    description: describeDetail,
                  };
                  store.commit('common/setQuestionnaire', titleObj);
                }
              });
          },
        },
        {
          type: 'config',
          label: '活动设置',
          icon: 'SettingTwoTone',
          permission: 'form:config',
          fnClick: record => {
            state.visible = true;
            activityCenterApi.getEdit('', record.id).then(res => {
              state.activeItem = res;
            });
          },
        },
        {
          type: 'analysis',
          label: '复制活动',
          icon: 'BlockOutlined',
          permission: 'form:analysis',
          fnClick: record => {
            activityCenterApi.getClone('', { id: record.id }).then(() => {
              message.success('操作成功');
              gridRef.value.refreshGrid();
            });
          },
        },
        {
          type: 'delete',
          permission: 'form:delete',
          fnClick: record => {
            activityCenterApi
              .delete('form:delete', { id: record.id })
              .then(() => {
                message.success('操作成功');
                gridRef.value.refreshGrid();
              });
          },
        },
      ],
      handleFnOkByEdit: value => {
        state.visible = value;
        gridRef.value.refreshGrid();
      },
    };
  },
};
</script>

<style scoped lang="less">
.iss-main {
  position: relative;
}

.iss-search-wrap {
  position: absolute;
  top: 20px;
  right: 26px;
}
</style>
