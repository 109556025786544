<template>
  <!--   :title="form.id? '活动设置' : '创建活动'"  -->
  <a-modal
    :visible="visible"
    :title="form.id ? '活动设置' : '创建活动'"
    :mask-closable="false"
    :width="600"
    centered
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <template #footer>
      <a-space>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" @click="handleOk">
          确认
        </a-button>
      </a-space>
    </template>
    <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="标题" v-bind="validateInfos.title">
        <a-input
          v-model:value="form.title"
          placeholder="请输入标题"
          maxlength="64"
        />
      </a-form-item>



      <a-form-item label="分享缩略图" v-bind="validateInfos.sharePicture">
        <!-- 限制图片大小  :limit-size="10"    isLt1MOpen -->
        <iss-image-upload
          v-model:value="form.sharePicture"
          :limit-size="1"
          list-type="picture-card"
          accept=".jpe,.jpeg,.jpg,.png"
        />
        <div class="font-color font-size">
          建议图片上传宽高比为1:1，大小不超过1M，格式为JPE、JPG、JPEG或PNG
        </div>
      </a-form-item>
      <a-form-item label="描述" v-bind="validateInfos.describeDetail">
        <a-textarea
          v-model:value="form.describeDetail"
          placeholder="请输入描述"
          show-count
          :maxlength="150"
        />
      </a-form-item>
      <a-form-item label="活动关键词" v-bind="validateInfos.keyword">
        <a-textarea
          v-model:value="form.keyword"
          show-count
          :maxlength="150"
          placeholder="请输入活动关键词，以便搜索引擎检索"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
// toRaw,
import { reactive, toRefs, watch } from 'vue';
// import { useRoute } from 'vue-router';
import { Form, message, Space} from 'ant-design-vue';
import IssImageUpload from '@/components/imageUpload';
import activityCenterApi from '@/api/activityCenter';
import { useStore } from 'vuex';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ASpace: Space,
    IssImageUpload,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    // const route = useRoute();
    const store = useStore();
    const required = { required: true, message: '不能为空' };
    const whitespace = {
      whitespace: true,
      message: '不能为空',
      trigger: 'change',
    };
    const form = reactive({
      id: '',
      title: '',
      sharePicture: '',
      keyword: null,
      describeDetail: '',
    });
    const state = reactive({
      formList: [],
      agreementList: [],
      meetingList: [],
    });
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      title: [
        required,
        whitespace,
        { max: 50, message: '长度不能超过50个字符' },
      ],
      sharePicture: [required],
      describeDetail: [required, { max: 150, message: '长度不能超过50个字符' }],
      keyword: [required, { max: 150, message: '长度不能超过50个字符' }],
      campaignName: [required],
    });
    watch(
      () => props.initValue,
      initValue => {
        if (initValue.id) {
          Object.assign(form, initValue);
        } else {
          resetFields();
        }
      }
    );

    return {
      ...toRefs(state),
      form,
      store,
      validateInfos,
      handleCancel: () => {
        context.emit('update:visible', false);
      },
      handleOk: () => {
        validate().then(() => {
          let type = 'add';
          form.id && (type = 'update');

          activityCenterApi[type]('', form).then(() => {
            message.success('操作成功');
            context.emit('fnOk', false);
            resetFields();
          });
        });
      },
    };
  },
};
</script>

<style scoped lang="less">
.font-color {
  color: #86909c;
}
.font-size {
  font-size: 12px;
}
</style>
